import { CHANGE_PARAMETER_VALUE, UPDATE_LOCATION } from '../actionTypes';

// prevent events from page load causing navigation events
const gracePeriod = 1_000;
let pageLoadTime = 0;

export default function syncProjectConfigWithQueryString({ getState }) {
  return (next) => (action) => {
    if (action.type === UPDATE_LOCATION) {
      pageLoadTime = Date.now();
    } else if (action.type === CHANGE_PARAMETER_VALUE && Date.now() - pageLoadTime > gracePeriod) {
      const { setParams, currentQuery } = getState();
      if (setParams && currentQuery) {
        setParams(currentQuery);
      }
    }

    return next(action);
  };
}
