import {
  FAILED_PROJECT_PREVIEW,
  FINISH_PROJECT_PREVIEW,
  START_PROJECT_PREVIEW,
} from '../actionTypes';

import fetchApi from '../../services/fetchApi';

export default function generateProjectPreview({ dispatch, getState }) {
  const onError = (errorMessage) =>
    dispatch({
      type: FAILED_PROJECT_PREVIEW,
      payload: { error: errorMessage },
    });

  return (next) => (action) => {
    if (action.type === START_PROJECT_PREVIEW) {
      const { projectConfig } = getState();

      const {
        name,
        website,
        ktorVersion,
        kotlinVersion,
        buildSystem,
        buildSystemArgs,
        engine,
        configurationIn,
        addSampleCode,
      } = Object.keys(projectConfig).reduce((result, key) => {
        const param = projectConfig[key];
        return {
          ...result,
          [key]: (param.id ? param.id : param.value) || param,
        };
      }, {});

      const data = {
        settings: {
          ['project_name']: name,
          ['company_website']: website,
          ['ktor_version']: ktorVersion,
          ['kotlin_version']: kotlinVersion,
          ['build_system']: buildSystem,
          ['build_system_args']: buildSystemArgs,
          engine,
        },
        features: projectConfig.plugins,
        configurationOption: configurationIn,
        addDefaultRoutes: addSampleCode,
        addWrapper: true,
      };

      fetchApi(
          '/project/preview',
          {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          },
      ).then((response) => {
        dispatch({
          type: FINISH_PROJECT_PREVIEW,
          payload: response,
        });
      }, ({ body }) => {
        return onError(body);
      });
    }

    return next(action);
  };
}
