import {
  CHANGE_PARAMETER_VALUE,
  INITIALIZE,
  LOAD_FEATURES,
  LOAD_SETTINGS,
  SHOW_ALERT,
  UPDATE_SERIALIZATION_MODEL,
} from '../actionTypes';
import { trackError } from '../../constants/analytics';
import fetchApi from '../../services/fetchApi';
import { makeErrorAlert } from '../../services/helper';
import { createSerializationModel } from '../../services/serializationModels';

export default function fetchInitializationData({ dispatch, getState }) {
  const loadSettings = (data) => dispatch({ type: LOAD_SETTINGS, payload: data });

  const updateSerializationModel = (projectModel) =>
    dispatch({ type: UPDATE_SERIALIZATION_MODEL, payload: projectModel });

  const changeParameterInProjectConfig = ({ name, value }) =>
    dispatch({ type: CHANGE_PARAMETER_VALUE, payload: { name, value } });

  const loadFeatures = (data) => {
    return dispatch({ type: LOAD_FEATURES, payload: data });
  };

  const getSyncedWithProjectConfigRoute = (
      queryParams,
      currentProjectConfig,
      serializationModel,
  ) => {
    try {
      return Object.keys(serializationModel).reduce((accumulator, key) => {
        const propertyModel = serializationModel[key];
        // assume this is a map (i.e., buildSystemArgs)
        if (!Object.hasOwn(propertyModel, 'deserialize')) {
          accumulator[key] = Object.keys(propertyModel).reduce((acc, subKey) => {
            acc[subKey] = [
              propertyModel[subKey]?.deserialize(queryParams[key + '.' + subKey]),
              currentProjectConfig[key]?.[subKey],
              propertyModel[subKey]?.getDefault(),
            ].filter((it) => !!it || it === false)[0];
            return acc;
          }, {});
        } else {
          accumulator[key] = [
            propertyModel.deserialize(queryParams[key]),
            currentProjectConfig[key],
            propertyModel.getDefault(),
          ].filter((it) => !!it || it === false)[0];
        }
        return accumulator;
      }, {});
    } catch (e) {
      console.warn('Failed to sync with query params', e);
      return currentProjectConfig;
    }
  };

  const onError = ({ message, status }) => {
    trackError(`load-settings-${status}`, true);
    return dispatch({
      type: SHOW_ALERT,
      payload: { alert: makeErrorAlert(message) },
    });
  };

  let serializationModel = createSerializationModel();

  return (next) => (action) => {
    if (action.type === INITIALIZE) {
      const { projectConfig } = getState();
      const { queryParams } = action.payload;

      fetchApi('/project/settings')
          .then((settings) => {
            loadSettings(settings);

            serializationModel = serializationModel.withSettings(settings);

            const updatedProjectConfig = getSyncedWithProjectConfigRoute(
                queryParams,
                projectConfig,
                serializationModel.models,
            );
            return fetchApi(`/features/${updatedProjectConfig.ktorVersion}`);
          })
          .then((allPluginsList) => {
            serializationModel = serializationModel.withPlugins(
                allPluginsList.map(({ xmlId, requiredFeatures }) => ({
                  id: xmlId,
                  requiredFeatures,
                })),
            );

            const updatedProjectConfig = getSyncedWithProjectConfigRoute(
                queryParams,
                projectConfig,
                serializationModel.models,
            );

            const projectConfigParametersToUpdate = Object.keys(
                serializationModel.models,
            ).filter((projectConfigKey) => {
              if (Array.isArray(updatedProjectConfig[projectConfigKey])) {
                const arrayToCompare = Array.isArray(
                    projectConfig[projectConfigKey],
                ) ?
                projectConfig[projectConfigKey] :
                [];
                return (
                  arrayToCompare.length !==
                  updatedProjectConfig[projectConfigKey].length ||
                updatedProjectConfig[projectConfigKey].some(
                    (it) =>
                      !arrayToCompare.some(
                          (arrayToCompareItem) => arrayToCompareItem === it,
                      ),
                )
                );
              }
              return (
                updatedProjectConfig[projectConfigKey] !== undefined &&
              updatedProjectConfig[projectConfigKey] !==
                projectConfig[projectConfigKey]
              );
            });

            projectConfigParametersToUpdate.forEach((key) => {
              if (serializationModel.models[key]) {
                changeParameterInProjectConfig({
                  name: key,
                  value: updatedProjectConfig[key],
                });
              }
            });

            updateSerializationModel(serializationModel);

            return loadFeatures(allPluginsList);
          })
          .catch(onError);
    }

    return next(action);
  };
}
