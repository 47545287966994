import React, { useEffect, useState } from 'react';
import { Button } from '@rescui/button';
import PropTypes from 'prop-types';
import { ShareIcon } from '@rescui/icons';
import { linkToPath } from '../services/helper';
import { Tooltip } from '@rescui/tooltip';

const PluginLinkButton = ({ plugin, size }) => {
  const link = linkToPath(`/p/${plugin.id}`);
  const [showCopiedMessage, setShowCopiedMessage] = useState(false);
  useEffect(() => {
    if (showCopiedMessage) {
      setTimeout(() => setShowCopiedMessage(false), 1_000);
    }
  }, [showCopiedMessage, setShowCopiedMessage]);

  return (
    <Tooltip content="Copied link to plugin!" isVisible={showCopiedMessage}>
      <Button
        mode="clear"
        icon={<ShareIcon />}
        size={size || 'xs'}
        onClick={() => {
          if (navigator.share) {
            navigator.share({
              title: `Ktor - ${plugin.name}`,
              url: link,
            }).then(() => {
              // TODO analytics
            }).catch((error) => {
              console.error('Error sharing', error);
            });
          } else if (navigator.clipboard) {
            navigator.clipboard.writeText(link).then(() => {
              setShowCopiedMessage(true);
            }).catch((error) => {
              console.error('Error copying', error);
            });
          }
        }}
      >
        Share
      </Button>
    </Tooltip>
  );
};

PluginLinkButton.propTypes = {
  plugin: PropTypes.object,
  size: PropTypes.string,
};

export default PluginLinkButton;
