import React, { useState } from 'react';
import { useTextStyles } from '@rescui/typography';
import { BlogIcon, GitHubIcon, HamburgerIcon, ThemeIcon, TwitterIcon } from '@rescui/icons';
import { useViewport } from '../services/helper';
import { Button } from '@rescui/button';
import { Dropdown } from '@rescui/dropdown';
import PropTypes from 'prop-types';
import cn from 'classnames';
import KtorLogo from '../KtorLogo';

const KtorPageNav = ({ className, toggleTheme }) => (
  <nav className={className}>
    <a className="selected-page">
      Create
    </a>
    <a href="https://ktor.io/docs/welcome.html">
      Docs
    </a>
    <a href="https://ktor.io/support">
      Support
    </a>
    <a href="https://github.com/ktorio/ktor" target="_blank" rel="noreferrer">
      <GitHubIcon />
    </a>
    <a href="https://twitter.com/jetbrainsktor" target="_blank" rel="noreferrer">
      <TwitterIcon />
    </a>
    <a href="https://blog.jetbrains.com/ktor/" target="_blank" rel="noreferrer">
      <BlogIcon />
    </a>
    <a onClick={toggleTheme}>
      <ThemeIcon />
    </a>
  </nav>
);

KtorPageNav.propTypes = {
  className: PropTypes.string,
  toggleTheme: PropTypes.func,
};

const KtorPageHeader = ({ toggleTheme }) => {
  const textCn = useTextStyles();
  const { width } = useViewport();
  const [showMenu, setShowMenu] = useState(false);
  return (
    <div className="ktor-page-header__wrapper">
      <header className="ktor-page-header">
        <div>
          <a href="https://ktor.io" className="ktor-page-header__logo">
            <KtorLogo />
            <h1 className={textCn('rs-h1')}>Ktor</h1>
          </a>
        </div>

        {
          width > 590 ?
            <KtorPageNav className={textCn('rs-text-1')} toggleTheme={toggleTheme} /> :
            <div style={{ textAlign: 'right' }}>
              <Dropdown trigger={
                <Button
                  mode="clear"
                  icon={<HamburgerIcon />}
                  onClick={() => setShowMenu(!showMenu)}
                />
              } isOpen={showMenu} renderInTrigger={true}>
                <KtorPageNav
                  className={cn(textCn('rs-text-2'), 'vertical')}
                  toggleTheme={toggleTheme}
                />
              </Dropdown>
            </div>
        }
      </header>
    </div>
  );
};

KtorPageHeader.propTypes = {
  toggleTheme: PropTypes.func,
};

export default KtorPageHeader;
