import { distinctById, getAllTransitiveChildren, makeArtifactName } from './helper';
import BuildSystemArgs from '../constants/buildSystemArgs';

function createConfigProperty(serialize, deserialize, getDefault) {
  return {
    serialize,
    deserialize,
    getDefault,
  };
}

function defaultSerializer(value) {
  return encodeURIComponent(`${value}`);
}

function defaultDeserializer(value) {
  return !value ?
    undefined :
    decodeURIComponent(`${value}`)
        .replace(/[&<>"' ]+/g, ' ')
        .trim();
}

function booleanDeserializer(strValue) {
  try {
    return Boolean(JSON.parse(strValue));
  } catch (err) {
    return undefined;
  }
}

function optionValueDeserializer(rawValue, { options = [] }) {
  const id = defaultDeserializer(rawValue)?.toLowerCase();
  return id ?
    options?.filter((option) => option.id?.toLowerCase() === id)[0]?.id :
    undefined;
}

function arraySerializer(arr) {
  return defaultSerializer((arr || []).join(','));
}

function arrayDeserializer(rawValue, possibleValueList) {
  if (!rawValue) {
    return undefined;
  }
  return defaultDeserializer(rawValue)
      .split(',')
      .map((value) =>
        optionValueDeserializer(value, { options: possibleValueList }),
      )
      .filter((key) => !!key);
}

const createSerializationModel = (optionsSettings, allPlugins = []) => {
  const name = createConfigProperty(
      defaultSerializer,
      defaultDeserializer,
      () => optionsSettings?.project_name?.default || '',
  );

  const website = createConfigProperty(
      defaultSerializer,
      defaultDeserializer,
      () => optionsSettings?.company_website?.default || '',
  );

  const artifact = createConfigProperty(
      defaultSerializer,
      defaultDeserializer,
      () => makeArtifactName(website.getDefault(), name.getDefault()),
  );

  const getDefaultFromOptionsSettings = (optionSetting) => {
    if (!optionSetting) {
      return '';
    }
    return optionSetting.default_id || (optionSetting.options || [])[0] || '';
  };

  const kotlinVersion = createConfigProperty(
      defaultSerializer,
      (rawValue) =>
        optionValueDeserializer(rawValue, optionsSettings?.kotlin_version || {}),
      () => getDefaultFromOptionsSettings(optionsSettings?.kotlin_version),
  );

  const ktorVersion = createConfigProperty(
      defaultSerializer,
      (rawValue) =>
        optionValueDeserializer(rawValue, optionsSettings?.ktor_version || {}),
      () => getDefaultFromOptionsSettings(optionsSettings?.ktor_version),
  );

  const buildSystem = createConfigProperty(
      defaultSerializer,
      (rawValue) =>
        optionValueDeserializer(rawValue, optionsSettings?.build_system || {}),
      () => getDefaultFromOptionsSettings(optionsSettings?.build_system),
  );

  const buildSystemArgs = {
    [BuildSystemArgs.CATALOG_ARG]: createConfigProperty(
        defaultSerializer,
        booleanDeserializer,
        () => true,
    ),
  };

  const engine = createConfigProperty(
      defaultSerializer,
      (rawValue) => optionValueDeserializer(rawValue, optionsSettings.engine || {}),
      () => getDefaultFromOptionsSettings(optionsSettings?.engine),
  );

  const configurationIn = createConfigProperty(
      defaultSerializer,
      (rawValue) =>
        optionValueDeserializer(rawValue, optionsSettings.configuration_in || {}),
      () => getDefaultFromOptionsSettings(optionsSettings.configuration_in),
  );

  const addSampleCode = createConfigProperty(
      defaultSerializer,
      booleanDeserializer,
      () => true,
  );

  const plugins = createConfigProperty(
      arraySerializer,
      (value) => {
        const deserializedRawIdentifiers =
        arrayDeserializer(value, allPlugins || []) || [];
        const featuresMap = allPlugins.reduce((accumulator, plugin) => {
          accumulator[plugin.id] = plugin;
          return accumulator;
        }, {});
        return distinctById(
            deserializedRawIdentifiers
                .reduce(
                    (result, plugin) =>
                      getAllTransitiveChildren(plugin, result, featuresMap),
                    [],
                )
                .concat(deserializedRawIdentifiers),
        );
      },
      () => [],
  );

  const models = {
    name,
    website,
    artifact,
    kotlinVersion,
    ktorVersion,
    buildSystem,
    buildSystemArgs,
    engine,
    configurationIn,
    addSampleCode,
    plugins,
  };

  return {
    models,

    withPlugins: (newListOfAllPlugins) =>
      createSerializationModel(optionsSettings, newListOfAllPlugins),

    withSettings: (settings) => createSerializationModel(settings, allPlugins),

    toSearchString: (projectConfig) => {
      const params = new URLSearchParams();
      Object.entries(models).forEach(([key, model]) => {
        if (!Object.hasOwn(projectConfig, key)) {
          return;
        }
        try {
          const modelValue = projectConfig[key];
          if (typeof modelValue === 'object' && !Object.hasOwn(model, 'serialize')) {
            Object.entries(model).forEach(([subKey, subModel]) => {
              params.append(key + '.' + subKey, subModel.serialize(modelValue[subKey]));
            });
          } else {
            const valueAsString = model.serialize(modelValue);
            params.append(key, valueAsString);
          }
        } catch (e) {
          console.warn(`Failed to serialize property "${key}"`, e);
        }
      });
      return params.toString();
    },
  };
};

export { createSerializationModel };
